<template>
    <div class="col-12">
        <div class="card p-fluid">
            <h5>ข้อมูลส่วนตัว</h5>
            <div class="field">
                <label>บัญชีผู้ใช้งาน</label>
                <InputText v-model="state.form.username" disabled="true" />
            </div>
            <div class="field">
                <label>ชื่อ</label>
                <InputText v-model="state.form.name" disabled="true" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue';
import { useAuthStore } from '@/store';
// import UserService from '@/service/UserService';
// import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const state = reactive({
    form: {},
    user: {},
});
const useAuth = useAuthStore();
// const router = useRouter();
const { t } = useI18n();

const getData = async () => {
    try {
        state.loading = true;
        state.form = await useAuth.getProfile();
        // const response = await UserService.findUser(state.form.id);
        // state.user = response.data.data;
    } catch (error) {
        // if (error.response.status === 403) {
        //     router.push({ name: 'Login' });
        // }
        //console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        // state.isLoading = false;
        state.loading = false;
    }
};

onMounted(async () => {
    getData();
});
</script>

<style lang="scss" scoped></style>
